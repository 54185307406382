import { addMinutes, format, intlFormatDistance, setHours, setMinutes } from 'date-fns'

/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat
 */
export function getRelativeTimeString(date: string | Date | number): string {
  return intlFormatDistance(date, new Date())
}

/**
 * Formats a given Date object to GMT+0 (UTC) with the specified time.
 * @param date - The Date object to format.
 * @param time - The time to set (e.g., "23:59").
 * @returns The formatted date string in ISO format (e.g., "2024-08-31T23:59:00.000Z").
 */
export const formatDateToGMT0 = (date: Date, time: string): string => {
  const [hours, minutes] = time.split(':').map(Number)

  const dateWithTime = setHours(setMinutes(date, minutes), hours)

  const utcDate = addMinutes(dateWithTime, dateWithTime.getTimezoneOffset())

  return format(utcDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
}
